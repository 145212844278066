<template>
  <v-card width="75%" style="margin: 15px auto;">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Завершенные тех. процессы</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <ProcessesTable :queryParams="{ status: 4 }" :key="tableUK">
        <template v-slot:select="{ process }">
          <v-checkbox v-model="selectedProcesses" :value="process.id"></v-checkbox>
        </template>
        <template v-slot:name="{ process }">
          <router-link :to="'/manufacture/process/view?id=' + process.id">
            {{process.template.name}}
          </router-link>
        </template>
      </ProcessesTable>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="success"
        :disabled="!selectedProcesses.length"
        @click="toArchive"
        >
        В архив
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import ProcessesTable from './components/ProcessesTable.vue'

export default {
  components: { ProcessesTable },
  data: () => ({ selectedProcesses: [], tableUK: 0 }),
  methods: {
    toArchive() {
      this.$axios.post('/manufacture/process/archive', {
        ids: this.selectedProcesses
      }).then(r => {
        this.selectedProcesses = []
        this.tableUK = Date.now()
      })
    }
  }
}
</script>
